/* eslint-disable import/prefer-default-export */
function arrayToCSV(data) {
  const header = Object.keys(data[0]).join(';');
  const rows = data.map((row: any) => Object.values(row).join(';'));
  return `${header}\n${rows.join('\n')}`;
}

export const exportCSV = (measure: any) => {
  const data = measure;
  // Erstellen Sie ein Blob-Objekt mit den CSV-Daten und einem MIME-Typ
  const csv = arrayToCSV(data);
  const csvWithBOM = `\uFEFF${csv}`;
  const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' });

  // Erzeugen Sie eine URL für das Blob-Objekt
  const url = window.URL.createObjectURL(blob);

  // Erstellen Sie ein unsichtbares a-Element, um die CSV-Datei herunterzuladen
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = 'data.csv';

  // Fügen Sie das a-Element zum DOM hinzu und klicken Sie es an, um den Download auszulösen
  document.body.appendChild(a);
  a.click();

  // Entfernen Sie das a-Element und den Blob-URL aus dem DOM
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
