/**
 * Actions denoter can be used to generate actions names with a prespecified suffix.
 * Usecase:
 *  instead of writing
 *  ```typescript
 *    export const ACTION_1 = 'path/to/container/action_1';
 *    export const ACTION_2 = 'path/to/container/action_2';
 *  ```
 *  we can use actions denoter:
 *  ```typescript
 *    const action = actionsDenoter('path/to/container');
 *    export const ACTION_1 = action('action_1');
 *    export const ACTION_2 = action('action_2');
 *  ```
 * @author michael.morosov
 *
 * @since 2022-02-02
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
const actionsDenoter = (prefix: string) => (
  (actionName: string) => `${prefix.replace(/\/+$/, '')}/${actionName}`);

export default actionsDenoter;
