/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Card, Space, Row, Col,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  PlusOutlined,
  TableOutlined,
} from '@ant-design/icons';
import React from 'react';
import styles from './styles.module.css';
/**
 *
 *
 * @author dogus.erguezel
 *
 * @since 2022-02-17
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
function Dashboard() {
  const navigate = useNavigate();

  function handleClickList() {
    navigate('/list');
  }
  function handleClickCreate() {
    navigate('/create');
  }
  return (
    <Row gutter={24} className={styles.container}>
      <Col className={styles['gutter-row']} span={12}>
        <div onClick={handleClickCreate}>
          <Card className={styles.card} title="Maßnahme anlegen">
            <Space>
              <PlusOutlined />
            </Space>
          </Card>
        </div>
      </Col>
      <Col className={styles['gutter-row']} span={12}>
        <div onClick={handleClickList}>
          <Card className={styles.card} title="Maßnahmenliste">
            <Space>
              <TableOutlined />
            </Space>
          </Card>
        </div>
      </Col>
    </Row>
  );
}
export default Dashboard;
