import Action from '../../utils/redux/Action';
import { APP, SET_IS_AUTHENTICATED_REQUESTED } from './actions';

export interface AppState {
  isAuthenticated: boolean;
}

const initialState: AppState = {
  isAuthenticated: false,
};

// eslint-disable-next-line default-param-last
export const appStateReducer = (state: AppState = initialState, action: Action): AppState => {
  switch (action.type) {
  case SET_IS_AUTHENTICATED_REQUESTED:
    return { ...state, isAuthenticated: action.payload.isAuthenticated };
  default:
    return state;
  }
};

const getState = (state: any): AppState => state[APP];

export const selectIsAuthenticated = (state: any): boolean => getState(state).isAuthenticated;
