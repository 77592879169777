/**
 * Abstract class describing fields and functions
 * required for a user authentication.
 *
 * @author michael.morosov
 *
 * @since 2022-02-02
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
export default abstract class Authenticator {
  public abstract initialize(): Promise<any>;

  public abstract isAuthenticated(): boolean;

  public abstract authenticate(): Promise<any> | any;

  /* eslint-disable */
  public abstract getToken(scopes: Array<string>): Promise<string>;
  /* eslint-enable */

  public abstract logOut(): Promise<any> | any;
}
