import { put, takeEvery } from 'redux-saga/effects';
import { selectedAuthenticator } from '../../auth/selected.authenticator';
import tryCatchSaga from '../../utils/redux/try.catch.saga';
import { AUTHENTICATION_REQUESTED, setIsAuthenticatedRequested } from './actions';

function* authenticate(): any {
  yield selectedAuthenticator.authenticate();
  yield put(setIsAuthenticatedRequested({ isAuthenticated: true }));
}

function* watchAuthenticationRequested() {
  yield takeEvery(AUTHENTICATION_REQUESTED, tryCatchSaga(authenticate));
}

export const appSagas: any[] = [
  watchAuthenticationRequested(),
];
