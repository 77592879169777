import axios from 'axios';
import { getBearerToken } from '../utils/authentication.utils';
import { config } from './Config';
import DataPoint from '../models/datapoint';
import { URL } from '../utils/constants';

export default class PointService {
  public static async getAll(idToken: string | null): Promise<DataPoint[]> {
    try {
      const finalToken = idToken || await getBearerToken();
      const response = await axios.get<DataPoint[]>(`${URL}api/points`, config(finalToken));
      return response.data;
    } catch (error) {
      console.error('Fehler beim Abrufen aller Points:', error);
      throw new Error('Fehler beim Abrufen aller Points. Bitte versuchen Sie es später erneut.');
    }
  }
}
