import axios from 'axios';
import { getBearerToken } from '../utils/authentication.utils';
import { config } from './Config';
import { URL } from '../utils/constants';

export default class MailService {
  public static async send(
    dataToPost: { measureID: string; mail: string; from: string; subject: string; to: string }[],
    idToken: string | null,
  ): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);

      await Promise.all(dataToPost.map((dtp) => axios.post(`${URL}api/mail`, dtp, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Senden von E-Mails:', error);
      throw new Error('Mail konnte nicht gesendet werden!');
    }
  }
}
