/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

export function getColumns(
  isButtonDisabled: (obj: any) => boolean,
  editMeasure: (obj: any) => void,
  handleMeasureRemove: (id: string) => void,
): any {
  return [
    {
      title: 'Id',
      dataIndex: 'measureID',
      key: 'measureID',
      sorter: (a: any, b:any) => a.measureID - b.measureID,
    },
    {
      title: 'Firma',
      dataIndex: 'company',
      key: 'company',
      sorter: (a: any, b:any) => a.company < b.company,
    },
    {
      title: 'Mail',
      dataIndex: 'mail',
      key: 'mail',
      sorter: (a: any, b:any) => a.mail < b.mail,
    },
    {
      title: 'Von-Zeit',
      dataIndex: 'fromTime',
      key: 'fromTime',
    },
    {
      title: 'Bis-Zeit',
      dataIndex: 'toTime',
      key: 'toTime',
    },
    {
      title: 'Kurztext',
      dataIndex: 'shortText',
      key: 'shortText',
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Von-Datum',
      dataIndex: 'fromDate',
      key: 'fromDate',
      sorter: (a: any, b:any) => +moment(a.fromDate).toDate()
      - +moment(b.fromDate).toDate(),
      render: (obj : string) => (
        getFullDate(obj)
      ),
    },
    {
      title: 'Bis-Datum',
      dataIndex: 'toDate',
      key: 'toDate',
      sorter: (a: any, b:any) => +moment(a.toDate).toDate()
      - +moment(b.toDate).toDate(),
      render: (obj : string) => (
        getFullDate(obj)
      ),
    },
    {
      title: 'zuletzt geändert am',
      dataIndex: 'lastChangedOn',
      key: 'lastChangedOn',
      sorter: (a: any, b: any) => (+moment(a.lastChangedOn).toDate() - +moment(b.lastChangedOn).toDate()),
      render: (date: Date) => (<span>{moment(date).format('DD-MM-YYYY HH:mm:ss')}</span>),
    },
    {
      title: 'erstellt am',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: any, b: any) => (+moment(a.createdAt).toDate() - +moment(b.createdAt).toDate()),
      render: (date: Date) => (<span>{moment(date).format('DD-MM-YYYY HH:mm:ss')}</span>),
    },
    {
      title: 'Status',
      key: 'isForwarded',
      sorter: (a: any, b:any) => {
        const s1 = a.isCanceled ? 'S' : a.isForwarded ? 'A' : a.isReleased ? 'V' : 'B';
        const s2 = b.isCanceled ? 'S' : b.isForwarded ? 'A' : b.isReleased ? 'V' : 'B';
        return s1 < s2 ? 1 : -1;
      },
      render: (text: string, record: any) => {
        let output;
        if (record.isCanceled === true) {
          output = 'storniert';
        } else if (record.isForwarded === true) {
          output = 'an OGE übermittelt';
        } else if (record.isReleased === true) {
          output = 'veröffentlicht';
        } else {
          output = 'bearbeitbar';
        }
        return output;
      },
    },
    {
      title: 'Löschen/Bearbeiten',
      dataIndex: '',
      key: 'delete',
      render: (obj :any) => (
        <div>
          {' '}
          {
            // eslint-disable-next-line no-nested-ternary
            (obj.isForwarded)
              ? (
                <div className={styles.delete}>
                  <div>
                    <Button disabled>
                      <DeleteOutlined />
                    </Button>
                  </div>
                  <div>
                    <Button onClick={() => editMeasure(obj)} disabled={isButtonDisabled(obj)}>
                      <EditOutlined />
                    </Button>
                  </div>
                </div>
              )
              : obj.isReleased
                ? (
                  <div className={styles.delete}>
                    <div>
                      <Button disabled>
                        <DeleteOutlined />
                      </Button>
                    </div>
                    <div>
                      <Button onClick={() => editMeasure(obj)} disabled={isButtonDisabled(obj)}>
                        <EditOutlined />
                      </Button>
                    </div>
                  </div>
                )
                : (
                  <div className={styles.delete}>
                    <div>
                      <Button onClick={() => handleMeasureRemove(obj.measureID)} disabled={isButtonDisabled(obj)}>
                        <DeleteOutlined />
                      </Button>
                    </div>
                    <div>
                      <Button onClick={() => editMeasure(obj)} disabled={isButtonDisabled(obj)}>
                        <EditOutlined />
                      </Button>
                    </div>
                  </div>
                )
          }
        </div>
      ),
    },
  ];
}

export const getFullDate = (date: string): string => {
  const dateAndTime = date.split('T');
  return dateAndTime[0].split('-').reverse().join('-');
};
