const columns: any = [
  {
    title: 'Fachschlüssel',
    dataIndex: 'fachschluesselID',
    key: 'fachschluesselID',
  },
  {
    title: 'Webname',
    dataIndex: 'webname',
    key: 'webname',
  },
  {
    title: 'Ort',
    dataIndex: 'ort',
    key: 'ort',
  },
  {
    title: 'Punktart',
    dataIndex: 'punktart',
    key: 'punktart',
  },
  {
    title: 'Richtung',
    dataIndex: 'richtung',
    key: 'richtung',
  },
  {
    title: 'Marktgebiet',
    dataIndex: 'marktgebiet',
    key: 'marktgebiet',
  },
  {
    title: 'Zone',
    dataIndex: 'zone',
    key: 'zone',
  },
  {
    title: 'ETSO/EIC-Code',
    dataIndex: 'eicCode',
    key: 'eicCode',
  },
];

export default columns;
