/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-console */

// Global imports.
import React, { useState, useEffect } from 'react';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import {
  Form, Button, DatePicker, Table, Collapse, Modal, Select, Input, Col,
} from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import { useNavigate } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import { selectedMeasureElementSet, selectedFachschluesselArraySet, selectedGroupIDSet }
  from '../MeasureList/actions';

// Views, containers and other components.
import ModalPoint from '../../views/ModalPoint';

// Stylesheets and media.
import styles from './styles.module.css';

import IDs from '../../models/ids';

// Utils, actions, sagas, etc. .
import DataPoint from '../../models/datapoint';
import Group from '../../models/group';
import Measure from '../../models/measure';
import Point from '../../models/point';

import {
  selectSelectedMeasureElement,
  selectSelectedFachschluesselIDArray,
  selectSelectedGroupID,
} from '../MeasureList/reducer';
import Company from '../../models/company';
import MeasureShortening from '../../models/MeasureShortening';
import { getColumnsPoints, getShorteningColumns } from './utils';

import GroupService from '../../services/GroupService';
import PointMovementService from '../../services/PointMovementService';
import CompanyService from '../../services/CompanyService';
import PointService from '../../services/PointService';
import MeasureService from '../../services/MeasureService';
import MeasureToPointService from '../../services/MeasureToPointService';
import PIService from '../../services/PIService';
import MailService from '../../services/MailService';
import { USER_ID_TOKEN } from '../../utils/local.storage';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

/**
 * Route representing measure input form providing new measures generation.
 *
 * @author tim.pauly
 * @author michael.morosov
 * @author dogus.erguezel
 *
 * @since 2022-02-17
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
function CreateMeasure(props: any) {
  const { dispatch } = props;
  const { userID } = props;
  const fachschluesselIDArray: any | undefined = useSelector(selectSelectedFachschluesselIDArray);
  const [points, setPoint]: [any[], any] = useState([]);
  const [companies, setCompanies]: [Company[], any] = useState([]);
  const [filteredPoints, setFilteredPoints]: [DataPoint[], any] = useState([]);
  const navigate = useNavigate();
  const [pointMovement, setPointMovement]: [Point[], any] = useState([]);
  const [, setMeasure]: [any[], any] = useState([]);
  const [group, setGroup]: [Group[], any] = useState([]);
  const [selectedGroupId, setSelectedGroupId]: [number | undefined, any] = useState(undefined);
  const [measureShortening, setMeasureShortening]: [MeasureShortening[], any] = useState([]);
  const [pointShortening, setPointShortening]: [any[], any] = useState([]);
  const [filteredPointShortening, setFilteredPointShortening]: [any[], any] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys]: [any[], any] = useState(fachschluesselIDArray || []);
  const [form] = Form.useForm();
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [, setEditingRowPointIndex]: [any | null, any] = useState(null);
  const [company, setCompany] = useState('');
  const [mail, setMail]: [string, any] = useState('');
  const [fromDate, setFromDate]: [Date, any] = useState(new Date());
  const [toDate, setToDate]: [Date, any] = useState(new Date());
  const [shortText, setShortText]: [string, any] = useState('');
  const [description, setDescription]: [string, any] = useState('');
  const [comment, setComment]: [string, any] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading]: [boolean, any] = useState(false);
  const [isUploadingRelease, setIsUploadingRelease]: [boolean, any] = useState(false);
  const [isUploadingForwarded, setIsUploadingForwarded]: [boolean, any] = useState(false);
  const [editingPoint, setEditingPoint]: [any | null, any] = useState(null);
  const measureElement: Measure | undefined = useSelector(selectSelectedMeasureElement);
  const groupIDUpdate: number | undefined = useSelector(selectSelectedGroupID);
  const [idArrays, setIDArrays]: [any[], any] = useState([]);
  const [measureFormRef]: [FormInstance<any>] = useForm();
  const [deletedGroups, setDeletedGroups]: [any, any] = useState([]);
  const [deletedPoints, setDeletedPoints]: [any, any] = useState([]);
  const handleFinish = () => {};
  const callback = () => {};

  const fetchMeasureCompany = async () => {
    const userCompanyElement = await CompanyService.getCompanyByUserId(userID, localStorage.getItem(USER_ID_TOKEN));
    setMeasure(await CompanyService.getCompanyById(userCompanyElement[0].companyID, localStorage.getItem(USER_ID_TOKEN)));
  };

  const fetchPoint = async () => {
    setPoint(await PointService.getAll(localStorage.getItem(USER_ID_TOKEN)));
  };

  const fetchCompanies = async () => {
    setCompanies(await CompanyService.getCompanyById('', localStorage.getItem(USER_ID_TOKEN)));
  };

  const filterPoints = async () => {
    const found: Company | undefined = companies.find((c: Company) => c.companyName === company);

    if (found !== undefined) {
      setFilteredPoints(
        points.filter((point: DataPoint) => point.companyID === found.companyID.toLowerCase()),
      );
    }
  };

  const fetchGroup = async () => {
    setGroup(await GroupService.getAll(localStorage.getItem(USER_ID_TOKEN)));
  };

  const fetchPointMovement = async () => {
    setPointMovement(await PointMovementService.getAll(localStorage.getItem(USER_ID_TOKEN)));
  };

  const fetchUserCompany = async () => {
    const userCompanyElement = await CompanyService.getCompanyByUserId(userID, localStorage.getItem(USER_ID_TOKEN));
    const companyElement = await CompanyService.getCompanyById(userCompanyElement[0].companyID, localStorage.getItem(USER_ID_TOKEN));
    console.log(userCompanyElement[0].mail);
    setMail(userCompanyElement[0].mail);
    // setMail(companyElement[0].mail);
    setCompany(companyElement[0].companyName);
    measureFormRef.setFieldsValue({
      // eslint-disable-next-line no-nested-ternary
      mail: measureElement
        ? measureElement.isForwarded ? measureElement.mail : userCompanyElement[0]?.mail
        : userCompanyElement[0]?.mail,
      company: companyElement[0].companyName,
    });
  };

  const handleCreate = () => {
    setIsUploading(true);
    measureFormRef.validateFields()
      .then(() => {
        if (!measureElement) {
          handleCreateInsert()
            .then(() => setIsUploading(false));
        } else {
          handleCreateUpdate()
            .then(() => setIsUploading(false));
        }
      })
      .catch((err: any) => {
        console.log(err);
        Modal.error({
          content: 'Bitte füllen Sie die Pflichtfelder aus!',
        });
        setIsUploading(false);
      });
  };

  const handleCreateRelease = async () => {
    setIsUploadingRelease(true);
    let measureSend: Measure;

    measureFormRef.validateFields()
      .then(async () => {
        let ids: IDs;
        if (!measureElement) {
          ids = await handleCreateInsert();
          measureSend = {
            measureID: ids.mID,
            company,
            mail,
            fromDate,
            toDate,
            fromTime: '06:00',
            toTime: '06:00',
            shortText,
            description,
            comment,
            isForwarded: false,
            lastChangedOn: new Date(),
            isReleased: false,
            userID,
            shorteningRecorded: false,
          };
        } else {
          ids = {
            mID: measureElement?.measureID,
            gID: groupIDUpdate,
          };
        }
        const data = {
          measure: measureElement || measureSend,
          selectedRowKeys,
          measureShortening,
          pointShortening,
        };
        PIService.postPIPM(data, localStorage.getItem(USER_ID_TOKEN)).then(() => {
          MeasureService.update([{
            measureID: ids.mID,
            isReleased: 1,
            lastChangedOn: moment().utc(),
          }], localStorage.getItem(USER_ID_TOKEN));
        }).then(() => {
          Modal.success({
            content: 'Die Maßnahmen wurde veröffentlicht!',
            onOk() {
              navigate('/');
            },
          });
          setIsUploadingRelease(false);
        }).catch((err) => {
          console.log(err);
          Modal.error({
            content: 'Die Maßnahmen konnten nicht veröffentlicht werden!',
          });
          setIsUploadingRelease(false);
          navigate('/');
        });
      })
      .catch((err: any) => {
        console.log(err);
        Modal.error({
          content: 'Bitte füllen Sie die Pflichtfelder aus!',
        });
        setIsUploadingRelease(false);
      });
  };

  const handleCreateForwarded = async () => {
    setIsUploadingForwarded(true);
    let measureSend: any;
    measureFormRef.validateFields()
      .then(async () => {
        let ids: IDs;
        if (!measureElement) {
          ids = await handleCreateInsert();
          measureSend = {
            measureID: ids.mID,
            company,
            mail,
            fromDate,
            toDate,
            fromTime: '06:00',
            toTime: '06:00',
            shortText,
            description,
            comment,
            isForwarded: false,
            lastChangedOn: new Date(),
            isReleased: false,
            userID,
            shorteningRecorded: false,
          };
        } else {
          ids = {
            mID: measureElement?.measureID,
            gID: groupIDUpdate,
          };
        }

        const data = {
          measure: measureElement || measureSend,
          selectedRowKeys,
          measureShortening,
          pointShortening,
        };
        PIService.postPIPM(data, localStorage.getItem(USER_ID_TOKEN)).then(() => {
          PIService.postPIBW(data, localStorage.getItem(USER_ID_TOKEN)).then(() => {
            Modal.success({
              content: 'Die Maßnahme wurde erfolgreich an OGE übermittelt!',
              onOk() {
                const dataToPost = [
                  {
                    measureID: !measureElement ? ids.mID.toString() : measureElement?.measureID.toString(),
                    mail: `Die Maßnahme mit der ID ${ids.mID.toString()} wurde erfolgreich versendet!`,
                    from: mail,
                    subject: `Maßnahme: ${ids.mID.toString()} wurde angelegt`,
                    to: 'extern.massnahmenportal@oge.net',
                  },
                ];

                MailService.send(dataToPost, localStorage.getItem(USER_ID_TOKEN));
                navigate('/');
              },
            });
            setIsUploadingForwarded(false);
            MeasureService.update([{
              measureID: ids.mID,
              isForwarded: 1,
              isReleased: 1,
              lastChangedOn: moment().utc(),
            }], localStorage.getItem(USER_ID_TOKEN)).catch(() => {
              Modal.error({
                content: 'Maßnahme konnte nicht bearbeitet werden!',
                onOk() {
                  navigate('/');
                },
              });
            });
          }).catch(
            (err) => {
              Modal.error({
                content: 'Maßnahme konnte nicht an BW gesendet werden!',
                onOk() {
                  navigate('/');
                },
              });
              console.log(`PM: ${err}`);
              setIsUploadingForwarded(false);
            },
          );
        }).catch((err) => {
          Modal.error({
            content: 'Maßnahme konnte nicht an PM gesendet werden!',
            onOk() {
              navigate('/');
            },
          });
          console.log(`PM: ${err}`);
          setIsUploadingForwarded(false);
        });
      })
      .catch((err: any) => {
        console.log(err);
        Modal.error({
          content: 'Bitte füllen Sie die Pflichtfelder aus!',
        });
        setIsUploadingForwarded(false);
      });
  };

  const onDeletePoint = (pID: number) => {
    setDeletedPoints([...deletedPoints, { pointID: pID }]);
    setPointShortening((pre: any[]) => pre.filter((element) => element.pointID !== pID));
  };

  const handleModalPointOk = (ids: number[]) => {
    setIsModalVisible(false);
    setSelectedRowKeys(
      ids.map((id: number) => points.find((entry: any) => entry.id === id)),
    );
  };

  const onPointSelect = (id: string) => {
    const pointElement = points.find((entry: any) => entry.fachschluesselID === id);
    const pointElementJustIDs = {
      id: pointElement.id,
      fachschluesselID: pointElement.fachschluesselID,
    };
    setSelectedRowKeys([
      ...selectedRowKeys,
      pointElementJustIDs,
    ]);
  };

  const onPointDeselect = (id: string) => {
    setSelectedRowKeys(
      selectedRowKeys.filter((entry: any) => entry.id !== id),
    );
  };

  const onDateintervallChange = (values: RangeValueType<Moment>) => {
    if (values) {
      const format: string = 'DD-MM-YYYY';
      const [from, to]: [Moment, Moment] = values as [Moment, Moment];

      setFromDate(from.toDate());
      if (from.toDate().getTime() === to.toDate().getTime()) {
        setToDate(to.add(1, 'day').toDate());
      } else {
        setToDate(to.toDate());
      }
      setMeasureShortening([
        {
          groupID: Math.floor(Math.random() * 99999999) + 1,
          fromDate: from.format(format),
          toDate: to.format(format),
          tvk: '',
        },
      ]);
    }
  };

  const onChangeEditablePoint = (value: {label: string;
    value: string;
    id: number;}) => {
    let gefunden = false;
    for (let index = 0; index < filteredPoints.length && !gefunden; index++) {
      if (filteredPoints[index].id === value.id) {
        gefunden = true;

        setEditingPoint((pre: any) => ({
          ...pre,
          node: filteredPoints[index].fachschluesselID,
          vip: filteredPoints[index].zone,
          webname: filteredPoints[index].webname,
          direction: filteredPoints[index].richtung,
          pointType: filteredPoints[index].punktart,
        }));
      }
    }
  };

  const onAddPoint = () => {
    const newPoint = {
      pointID: Math.floor(Math.random() * 99999999) + 1,
      node: '',
      webname: '',
      direction: '',
      pointType: '',
      vip: '',
      dzkBookable: false,
      fzk: false,
      bfzk: false,
      dzk: false,
      dzkOne: false,
      bzk: false,
      groupID: selectedGroupId,
    };
    setPointShortening([...pointShortening, newPoint]);
    onEditPoint(newPoint);
  };

  const onAddGroup = () => {
    const groupID = Math.floor(Math.random() * 99999999) + 1;
    const newMeasure: MeasureShortening = {
      groupID,
      fromDate: moment(fromDate).format('DD-MM-YYYY'),
      toDate: moment(toDate).format('DD-MM-YYYY'),
      tvk: 0,
    };

    setMeasureShortening([...measureShortening, newMeasure]);
  };

  const onDeleteGroup = (gID: number) => {
    if (measureElement) {
      setDeletedGroups([...deletedGroups, { groupID: gID }]);
    }
    const newMeasureShortening: MeasureShortening[] = measureShortening.filter((element) => element.groupID !== gID);
    setMeasureShortening(newMeasureShortening);
  };

  const onFinishGroup = (value: any) => {
    // eslint-disable-next-line no-param-reassign
    measureShortening.map((entry) => { if (entry.groupID === value.groupID) { entry.tvk = value.tvk; return entry; } return entry; });
    setEditingRowIndex(null);
  };

  const onEditPoint = (record: any) => {
    setIsEditing(true);
    setEditingPoint({ ...record });
  };

  const resetEditing = () => {
    setIsEditing(false);
    setEditingPoint(null);
  };

  const handleCreateInsert = async () => {
    const mID = Math.floor(Math.random() * 99999999) + 1;
    const gID = Math.floor(Math.random() * 99999999) + 1;
    const ids = {
      mID,
      gID,
    };

    await MeasureService.create({
      measureID: mID,
      userID,
      company,
      mail,
      fromDate: moment(fromDate),
      toDate: moment(toDate),
      fromTime: '06:00',
      toTime: '06:00',
      shortText,
      description,
      comment,
      lastChangedOn: moment().utc(),
    }, localStorage.getItem(USER_ID_TOKEN)).catch(() => {
      Modal.error({
        content: 'Die Maßnahme konnte nicht angelegt werden!',
        onOk() {
          navigate('/');
        },
      });

      return '';
    });

    for (let index = 0; index < selectedRowKeys.length; index++) {
      // eslint-disable-next-line no-await-in-loop
      await MeasureToPointService.create([{
        measureID: mID,
        id: selectedRowKeys[index].id,
        fachschluesselID: selectedRowKeys[index].fachschluesselID,
      }], localStorage.getItem(USER_ID_TOKEN)).catch(() => {
        Modal.error({
          content: 'Punkt konnte nicht angelegt werden!',
          onOk() {
            navigate('/');
          },
        });
      });
    }

    for (let index = 0; index < measureShortening.length; index++) {
      // eslint-disable-next-line no-await-in-loop
      await GroupService.create({
        groupID: measureShortening[index].groupID,
        fromDate: measureShortening[index].fromDate,
        toDate: measureShortening[index].toDate,
        tvk: measureShortening[index].tvk,
        measureID: mID,
      }, localStorage.getItem(USER_ID_TOKEN)).catch(() => {
        Modal.error({
          content: 'Die Gruppe konnte nicht angelegt werden!',
          onOk() {
            navigate('/');
          },
        });
        return '';
      });
    }

    for (let index = 0; index < pointShortening.length; index++) {
      // eslint-disable-next-line no-await-in-loop
      await PointMovementService.create([{
        pointID: pointShortening[index].pointID,
        groupID: pointShortening[index].groupID,
        node: pointShortening[index].node,
        webname: pointShortening[index].webname,
        direction: pointShortening[index].direction,
        pointType: pointShortening[index].pointType,
        location: pointShortening[index].location,
        marketArea: pointShortening[index].marketArea,
        vip: pointShortening[index].vip,
        zone: pointShortening[index].zone,
        dzkBookable: pointShortening[index].dzkBookable,
        fzk: pointShortening[index].fzk,
        bfzk: pointShortening[index].bfzk,
        dzk: pointShortening[index].dzk,
        dzkOne: pointShortening[index].dzkOne,
        bzk: pointShortening[index].bzk,
      }], localStorage.getItem(USER_ID_TOKEN)).then(async () => MeasureService.update([{
        measureID: mID,
        shorteningRecorded: 1,
      }], localStorage.getItem(USER_ID_TOKEN)).catch((err) => err))
        .catch(() => {
          Modal.error({
            content: 'Punkt konnte nicht angelegt werden!',
            onOk() {
              navigate('/');
            },
          });
        });
    }

    const message = pointShortening.length === 0
      ? 'Maßnahme wurden angelegt!' : 'Maßnahme, Gruppen und Punkte wurden angelegt!';
    Modal.success({
      content: message,
      onOk() {
        navigate('/');
      },
    });
    return ids;
  };

  const handleCreateUpdate = async () => {
    await MeasureService.update([{
      measureID: measureElement?.measureID,
      company: !company ? measureElement?.company : company,
      mail: !mail ? measureElement?.mail : mail,
      fromDate: !fromDate ? moment(measureElement?.fromDate) : fromDate,
      toDate: !toDate ? moment(measureElement?.toDate) : toDate,
      fromTime: '06:00',
      toTime: '06:00',
      shortText: !shortText ? measureElement?.shortText : shortText,
      description: !description ? measureElement?.description : description,
      comment: !comment ? measureElement?.comment : comment,
      lastChangedOn: moment().utc(),
    }], localStorage.getItem(USER_ID_TOKEN)).catch(() => {
      Modal.error({
        content: 'Maßnahme konnte nicht bearbeitet werden!',
        onOk() {
          navigate('/');
        },
      });
    });

    // eslint-disable-next-line max-len
    await MeasureToPointService.delete([{ measureID: measureElement?.measureID }], localStorage.getItem(USER_ID_TOKEN)).catch((err) => { console.log(err); });

    for (let index = 0; index < selectedRowKeys.length; index++) {
    // eslint-disable-next-line no-await-in-loop
      await MeasureToPointService.create([{
        measureID: measureElement?.measureID,
        id: selectedRowKeys[index].id,
        fachschluesselID: selectedRowKeys[index].fachschluesselID,
      }], localStorage.getItem(USER_ID_TOKEN)).catch(() => {
        Modal.error({
          content: 'Punkte konnte nicht bearbeitet werden!',
          onOk() {
            navigate('/');
          },
        });
      });
    }

    // delete groups
    deletedGroups.forEach(async (element: any) => {
      await GroupService.delete([{
        groupID: element.groupID,
      }], localStorage.getItem(USER_ID_TOKEN)).catch(() => {
        Modal.error({
          content: 'Gruppe konnte nicht gelöscht werden!',
          onOk() {
            navigate('/');
          },
        });
      });
    });

    // create and update groups
    const filteredGroups = group.filter((g: Group) => +g.measureID === +measureElement!.measureID);
    measureShortening.forEach(async (element: any) => {
      const found: Group | undefined = filteredGroups.find((g: Group) => +g.groupID === +element.groupID);
      if (found) {
        await GroupService.update([{
          groupID: element.groupID,
          fromDate: element.length === 0 ? '' : moment(element.fromDate),
          toDate: element.length === 0 ? '' : moment(element.toDate),
          tvk: element.length === 0 ? '' : element.tvk,
        }], localStorage.getItem(USER_ID_TOKEN)).catch(() => {
          Modal.error({
            content: 'Gruppe konnte nicht bearbeitet werden!',
            onOk() {
              navigate('/');
            },
          });
        });
      } else {
        await GroupService.create({
          groupID: element.groupID,
          fromDate: element.fromDate,
          toDate: element.toDate,
          tvk: element.tvk,
          measureID: measureElement?.measureID,
        }, localStorage.getItem(USER_ID_TOKEN)).catch(() => {
          Modal.error({
            content: 'Die Gruppe konnte nicht angelegt werden!',
            onOk() {
              navigate('/');
            },
          });
          return '';
        });
      }
    });

    // delete point movement
    deletedPoints.forEach(async (element: any) => {
      console.log(element);
      await PointMovementService.delete([{
        pointsID: element.groupID,
      }], localStorage.getItem(USER_ID_TOKEN)).catch(() => {
        Modal.error({
          content: 'Gruppe konnte nicht bearbeitet werden!',
          onOk() {
            navigate('/');
          },
        });
      });
    });

    // create and update points
    const filteredShortenings: MeasureShortening[] = pointShortening.filter((element: any) => measureShortening.some((g: MeasureShortening) => +g.groupID === +element.groupID));
    filteredShortenings.forEach(async (element: any) => {
      const found: Point | undefined = pointShortening.find((p: Point) => +p.pointID === +element.pointID);

      if (found) {
        await PointMovementService.update([{
          pointID: element.pointID,
          groupID: element.groupID,
          node: element.node,
          webname: element.webname,
          direction: element.direction,
          pointType: element.pointType,
          location: element.location,
          marketArea: element.marketArea,
          vip: element.vip,
          zone: element.zone,
          dzkBookable: element.dzkBookable,
          fzk: element.fzk,
          bfzk: element.bfzk,
          dzk: element.dzk,
          dzkOne: element.dzkOne,
          bzk: element.bzk,
        }], localStorage.getItem(USER_ID_TOKEN)).catch(() => {
          Modal.error({
            content: 'Punkte konnte nicht bearbeitet werden!',
            onOk() {
              navigate('/');
            },
          });
        });
      } else {
        await PointMovementService.create({
          pointID: element.pointID,
          groupID: element.groupID,
          node: element.node,
          webname: element.webname,
          direction: element.direction,
          pointType: element.pointType,
          location: element.location,
          marketArea: element.marketArea,
          vip: element.vip,
          zone: element.zone,
          dzkBookable: element.dzkBookable,
          fzk: element.fzk,
          bfzk: element.bfzk,
          dzk: element.dzk,
          dzkOne: element.dzkOne,
          bzk: element.bzk,
        }, localStorage.getItem(USER_ID_TOKEN)).catch(() => {
          Modal.error({
            content: 'Die Punkte konnte nicht angelegt werden!',
            onOk() {
              navigate('/');
            },
          });
          return '';
        });
      }
    });

    const message = pointShortening.length === 0
      ? 'Maßnahme wurden angepasst!' : 'Maßnahme, Gruppen und Punkte wurden angepasst!';
    Modal.success({
      content: message,
      onOk() {
        navigate('/');
      },
    });
  };

  const handleCheckboxChangeFactoryDZKBOOKABLE = (checked: any, entry: any) => (clickEvent: any) => {
    setPointShortening(pointShortening.map((ps: any) => {
      if (ps.pointID === entry.pointID) {
        return { ...entry, dzkBookable: clickEvent.target.checked };
      }

      return { ...ps };
    }));
  };

  const handleCheckboxChangeFactoryFZK = (checked: any, entry: any) => (clickEvent: any) => {
    setPointShortening(pointShortening.map((ps: any) => {
      if (ps.pointID === entry.pointID) {
        return { ...entry, fzk: clickEvent.target.checked };
      }

      return { ...ps };
    }));
  };

  const handleCheckboxChangeFactoryBFZK = (checked: any, entry: any) => (clickEvent: any) => {
    setPointShortening(pointShortening.map((ps: any) => {
      if (ps.pointID === entry.pointID) {
        return { ...entry, bfzk: clickEvent.target.checked };
      }

      return { ...ps };
    }));
  };

  const handleCheckboxChangeFactoryDZK = (checked: any, entry: any) => (clickEvent: any) => {
    setPointShortening(pointShortening.map((ps: any) => {
      if (ps.pointID === entry.pointID) {
        return { ...entry, dzk: clickEvent.target.checked };
      }

      return { ...ps };
    }));
  };

  const handleCheckboxChangeFactoryDZKONE = (checked: any, entry: any) => (clickEvent: any) => {
    setPointShortening(pointShortening.map((ps: any) => {
      if (ps.pointID === entry.pointID) {
        return { ...entry, dzkOne: clickEvent.target.checked };
      }

      return { ...ps };
    }));
  };

  const handleCheckboxChangeFactoryBZK = (checked: any, entry: any) => (clickEvent: any) => {
    setPointShortening(pointShortening.map((ps: any) => {
      if (ps.pointID === entry.pointID) {
        return { ...entry, bzk: clickEvent.target.checked };
      }

      return { ...ps };
    }));
  };

  const getIsAbsendenEnabled = (): boolean => (pointShortening.map((point: Point): boolean => [
    point.bfzk,
    point.bzk,
    point.dzk, point.dzkBookable, point.dzkOne, point.fzk]
    .find((b: boolean) => !!b) ?? false)
    .find((b: boolean) => !b) ?? true) && pointShortening.length > 0;

  const onChangeGroupToDate = (event: any, groupID: number) => {
    const format: string = 'DD-MM-YYYY';

    setMeasureShortening(measureShortening.map((entry: MeasureShortening) => {
      if (entry.groupID === groupID) {
        const newEntry = {
          ...entry,
          toDate: moment(event.target.value).format(format),
        };
        return newEntry;
      }
      return entry;
    }));
  };

  const onChangeGroupFromDate = (event: any, groupID: number) => {
    const format: string = 'DD-MM-YYYY';

    setMeasureShortening(measureShortening.map((entry: MeasureShortening) => {
      if (entry.groupID === groupID) {
        const newEntry: MeasureShortening = {
          ...entry,
          fromDate: moment(event.target.value).format(format),
        };
        return newEntry;
      }
      return entry;
    }));
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const groupTableSelection = {
    onSelect: (record, selected, selectedRows) => {
      if (selectedRows.length === 1) {
        setSelectedGroupId(selectedRows[0].groupID);
      } else {
        setSelectedGroupId(undefined);
      }
    },
  };

  useEffect(() => {
    if (selectedGroupId !== undefined) {
      setFilteredPointShortening(
        pointShortening.filter((point: any) => point.groupID === selectedGroupId),
      );
    } else {
      setFilteredPointShortening([]);
    }
  }, [selectedGroupId, pointShortening]);

  useEffect(() => {
    const today = new Date();
    setFromDate(today);
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    setToDate(tomorrow);

    fetchMeasureCompany();
    fetchPoint();
    fetchCompanies();
    fetchGroup();
    fetchUserCompany();
    fetchPointMovement();

    const idArray : any = [];
    if (fachschluesselIDArray) {
      fachschluesselIDArray.forEach((element) :any => {
        idArray.push(element.fachschluesselID);
      });
      console.log(idArray);
      setIDArrays(idArray);
    }
  }, []);

  useEffect(() => {
    filterPoints();
  }, [company, pointMovement]);

  useEffect(() => { }, [measureShortening]);

  useEffect(() => {
    if (measureElement) {
      setFromDate(moment(measureElement.fromDate).format('DD-MM-YYYY'));
      setToDate(moment(measureElement.toDate).format('DD-MM-YYYY'));
    }
  }, []);

  useEffect(() => {
    if (measureElement) {
      const newMeasures: any[] = [];
      for (let index = 0; index < group.length; index++) {
        if (+group[index].measureID === +measureElement.measureID) {
          const newGroup = {
            groupID: group[index].groupID,
            measureID: group[index].measureID,
            fromDate: moment(group[index].fromDate, 'DD-MM-YYYY'),
            toDate: moment(group[index].toDate, 'DD-MM-YYYY'),
            tvk: group[index].tvk,
          };
          newMeasures.push(newGroup);
        }
      }
      setMeasureShortening(newMeasures);
    }
  }, [group]);

  useEffect(() => {
    if (measureElement) {
      const pointElementArray: Point[] = [];
      for (let index = 0; index < pointMovement.length; index++) {
        const pointElement: Point = {
          pointID: pointMovement[index].pointID,
          groupID: pointMovement[index].groupID,
          node: pointMovement[index].node,
          webname: pointMovement[index].webname,
          direction: pointMovement[index].direction,
          pointType: pointMovement[index].pointType,
          location: pointMovement[index].location,
          marketArea: pointMovement[index].marketArea,
          vip: pointMovement[index].vip,
          zone: pointMovement[index].zone,
          dzkBookable: pointMovement[index].dzkBookable,
          fzk: pointMovement[index].fzk,
          bfzk: pointMovement[index].bfzk,
          dzk: pointMovement[index].dzk,
          dzkOne: pointMovement[index].dzkOne,
          bzk: pointMovement[index].bzk,
        };
        pointElementArray.push(pointElement);
      }
      setPointShortening(pointElementArray);
    }
  }, [pointMovement]);

  useEffect(() => {
    fetchPoint();
  }, []);
  useEffect(() => () => {
    dispatch(selectedFachschluesselArraySet(null));
    dispatch(selectedMeasureElementSet(null));
    dispatch(selectedGroupIDSet(null));
  }, []);
  if (company) {
    return (
      <div className={styles.center}>
        <Collapse defaultActiveKey={['panel_One']} onChange={callback}>
          <Panel header="Maßnahme erfassen" key="panel_One">
            <Form
              form={measureFormRef}
              layout="vertical"
              onFinish={handleFinish}
              validateMessages={validateMessages}
            >
              {/* Firma */}
              <Form.Item
                label="Firma"
                name="company"
              >
                <Input
                  disabled
                />
              </Form.Item>

              {/* E-Mail */}
              <Form.Item label="E-Mail" name="mail">
                <Input
                  defaultValue={
                    measureElement?.mail
                  }
                  disabled
                />
              </Form.Item>
              <Col span={24}>
                <div className={styles.dateRow}>
                  {/* Date */}
                  <Form.Item
                    label="Datum"
                    name="date"
                    initialValue={measureElement
                      ? [moment(measureElement?.fromDate),
                        moment(measureElement?.toDate)]
                      : [moment(fromDate),
                        moment(toDate)]}
                    rules={[{ required: true }]}
                  >
                    <RangePicker
                      style={{ width: '100%' }}
                      disabledDate={(d: any) => !d || d.isSameOrBefore(moment())}
                      onChange={(values: any) => onDateintervallChange(values)}
                      format="DD-MM-YYYY"
                      suffixIcon={null}
                      disabled={measureElement?.isReleased === true}
                    />
                  </Form.Item>

                  <Form.Item label="Von-Zeit" name="times1" initialValue="06:00">
                    <Input disabled />
                  </Form.Item>

                  <Form.Item label="Bis-Zeit" name="times2" initialValue="06:00">
                    {/* Time To */}
                    <Input disabled />
                  </Form.Item>
                </div>
              </Col>

              {/* Short description */}
              <Form.Item
                label="Kurztext"
                name="kurztext"
                initialValue={measureElement?.shortText || ''}
                rules={[{ required: true }]}
              >
                <Input
                  showCount
                  maxLength={40}
                  disabled={measureElement?.isReleased === true || measureElement?.isForwarded === true}
                  onChange={(e: any) => setShortText(e.target.value)}
                />
              </Form.Item>

              {/* Description */}
              <Form.Item
                label="Beschreibung"
                name="beschreibung"
                initialValue={measureElement?.description || ''}
                rules={[{ required: true }]}
              >
                <Input
                  showCount
                  maxLength={255}
                  disabled={measureElement?.isReleased === true || measureElement?.isForwarded === true}
                  onChange={(e: any) => setDescription(e.target.value)}
                />
              </Form.Item>

              {/* Points */}
              <Form.Item label="Punkte für Veröffentlichung" name="punkte" required>
                <div style={{ display: 'flex' }}>
                  <Col span={22}>
                    <Form.Item>
                      <Select
                        disabled={measureElement?.isReleased === true || measureElement?.isForwarded === true}
                        mode="multiple"
                        defaultValue={idArrays || []}
                        onSelect={onPointSelect}
                        onDeselect={onPointDeselect}
                        options={filteredPoints.map((datapoint: DataPoint) => ({
                          label: datapoint.fachschluesselID,
                          value: datapoint.fachschluesselID,
                          id: datapoint.id,
                        })) || []}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Button onClick={() => setIsModalVisible(!isModalVisible)}>
                      <EditOutlined />
                    </Button>
                  </Col>
                </div>
              </Form.Item>

              {/* Comment */}
              <Form.Item
                label="Kommentar"
                initialValue={measureElement?.comment || ''}
                name="textarea"
                rules={[{ required: true }]}
              >
                <TextArea
                  disabled={measureElement?.isReleased === true || measureElement?.isForwarded === true}
                  rows={4}
                  showCount
                  maxLength={255}
                  onChange={(e: any) => setComment(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Panel>
          <Panel header="Kürzung erfassen" key="panel_Two">
            <Form form={form} onFinish={onFinishGroup}>
              <Table
                columns={getShorteningColumns(
                  measureElement,
                  fromDate,
                  toDate,
                  editingRowIndex,
                  form,
                  setEditingRowIndex,
                  onChangeGroupToDate,
                  onChangeGroupFromDate,
                  onFinishGroup,
                  onDeleteGroup,
                )}
                dataSource={measureShortening}
                rowKey="groupID"
                rowSelection={groupTableSelection}
              />
            </Form>
            <Button
              onClick={onAddGroup}
              className={styles.addPoint}
            >
              Neue Gruppe hinzufügen
            </Button>
            <Table
              dataSource={selectedGroupId ? filteredPointShortening : []}
              columns={getColumnsPoints(
                measureElement,
                handleCheckboxChangeFactoryDZKBOOKABLE,
                handleCheckboxChangeFactoryFZK,
                handleCheckboxChangeFactoryBFZK,
                handleCheckboxChangeFactoryDZK,
                handleCheckboxChangeFactoryDZKONE,
                handleCheckboxChangeFactoryBZK,
                setEditingRowPointIndex,
                onEditPoint,
                onDeletePoint,
              )}
            />
            {
              selectedGroupId !== undefined && (
                <Button
                  onClick={onAddPoint}
                  className={styles.addPoint}
                  disabled={measureElement ? measureElement?.isForwarded === true : false}
                >
                  Neuen Punkt zur Massnahme hinzufügen
                </Button>
              )
            }

            <Modal
              title="Editiere Punkt"
              open={isEditing}
              onCancel={() => {
                onDeletePoint(editingPoint.pointID);
                resetEditing();
              }}
              onOk={() => {
                let gefunden = false;
                let gefundenDirection = false;

                for (const point of pointShortening) {
                  if (point.node === editingPoint.node) {
                    gefunden = true;
                    break;
                  }

                  if (point.groupID === editingPoint.groupID) {
                    if (point.direction !== editingPoint.direction) {
                      if (point.direction !== '') {
                        gefundenDirection = true;
                        break;
                      }
                    }
                  }
                }
                if (!gefunden) {
                  if (gefundenDirection) {
                    Modal.info({
                      content: 'Der Punkt zeigt die falsche Richtung an!',
                    });
                  } else if (editingPoint.node !== '') {
                    setPointShortening((prevPointShortening) => prevPointShortening.map((point) => (
                      point.pointID === editingPoint.pointID ? editingPoint : point
                    )));
                    resetEditing();
                  }
                } else {
                  Modal.info({
                    content: 'Der Punkt wurde schon ausgewählt!',
                  });
                }
              }}
            >
              <div>
                <div className={styles.modalFirst}>
                  <Select
                    className={styles.modalFirstEditing}
                    defaultValue={editingPoint ? editingPoint?.webname : ''}
                    showSearch
                    value={editingPoint?.webname}
                    onChange={(e: any, option: any) => onChangeEditablePoint(option)}
                    placeholder="Webname"
                    options={filteredPoints.map((datapoint: DataPoint) => ({
                      label: `${datapoint.webname} - ${datapoint.fachschluesselID}`,
                      value: `${datapoint.webname} - ${datapoint.fachschluesselID}`,
                      id: datapoint.id,
                    }))}
                  />

                </div>
                <div className={styles.modalFirst}>
                  <Select
                    className={styles.modalFirstEditing}
                    defaultValue={editingPoint ? editingPoint?.node : ''}
                    showSearch
                    value={editingPoint?.node}
                    onChange={(e: any, option: any) => onChangeEditablePoint(option)}
                    placeholder="Punkt/VIP"
                    options={filteredPoints.map((datapoint: DataPoint) => ({
                      label: datapoint.fachschluesselID,
                      value: datapoint.fachschluesselID,
                      id: datapoint.id,
                    }))}
                  />
                </div>
                <div className={styles.modal}>
                  <Input
                    disabled
                    value={editingPoint?.direction}
                    defaultValue={editingPoint?.direction}
                    placeholder="Richtung"
                  />

                </div>
                <div className={styles.modal}>
                  <Input
                    disabled
                    value={editingPoint?.pointType}
                    defaultValue={editingPoint?.pointType}
                    placeholder="Punktart"
                  />

                </div>
                <div className={styles.modal}>
                  <Input
                    disabled
                    value={editingPoint?.vip}
                    defaultValue={editingPoint?.vip}
                    placeholder="Vip"
                  />
                </div>
              </div>
            </Modal>
          </Panel>
        </Collapse>
        <div className={styles.btn}>
          <div>
            <Button
              loading={isUploading}
              htmlType="submit"
              className={styles.saveBtn}
              type="primary"
              onClick={handleCreate}
              disabled={measureElement?.isForwarded === true}
            >
              Speichern
            </Button>
          </div>
          <div className={styles.btn2}>
            <Button
              loading={isUploadingRelease}
              htmlType="submit"
              className={styles.saveBtn}
              type="primary"
              onClick={handleCreateRelease}
              disabled={measureElement ? measureElement?.isReleased === true : false}
            >
              Veröffentlichen
            </Button>
          </div>
          <div className={styles.btn2}>
            <Button
              loading={isUploadingForwarded}
              htmlType="submit"
              className={styles.saveBtn}
              type="primary"
              onClick={handleCreateForwarded}
              disabled={!getIsAbsendenEnabled() || measureElement?.isForwarded === true}
            >
              Absenden
            </Button>
          </div>

        </div>

        <ModalPoint
          isVisible={isModalVisible}
          handleOk={handleModalPointOk}
          handleCancel={() => setIsModalVisible(false)}
          data={filteredPoints}
          // data={points}
          selectedRowKeyParent={
            selectedRowKeys.map((entry: any) => entry.id) as any
          }
        />
      </div>
    );
  }
  return (
    <div>
      Es wird geprüft, ob Sie bereits einem Unternehmen zugeordnet sind.
      Sollte dies noch nicht der Fall sein, läuft eine Evaluierung durch die Open-Grid-Europe.
    </div>
  );
}
export default connect()(CreateMeasure);
