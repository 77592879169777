import { AxiosRequestConfig } from 'axios';

// eslint-disable-next-line func-names, no-unused-vars, import/prefer-default-export
export const config = function (token: string): AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      /*
        'x-ms-client-principal': /test/i.test(process.env.REACT_APP_NODE_ENV ?? '') ? btoa(JSON.stringify({
          claims: [{
            typ: 'roles',
            val: 'admin',
          }],
        })) : undefined as any,
  */
    },
  };
};
