export const SELECTED_MEASURE_ELEMENT_SET = 'routes/MeasureList/SELECTED_MEASURE_ELEMENT_SET';
export const SELECTED_FACHSCHLUESSELID_ARRAY_SET = 'routes/MeasureList/SELECTED_FACHSCHLUESSELID_ARRAY_SET';
export const SELECTED_GROUPID_SET = 'routes/MeasureList/SELECTED_GROUPID_SET';

// creates an Action instance => { type: string, payload: any}
export function selectedMeasureElementSet(measureElement: any) {
  return {
    type: SELECTED_MEASURE_ELEMENT_SET,
    payload: measureElement,
  };
}
export function selectedFachschluesselArraySet(fachschluesselIDArray: any) {
  return {
    type: SELECTED_FACHSCHLUESSELID_ARRAY_SET,
    payload: fachschluesselIDArray,
  };
}
/*
export function selectedUserIDs(userIDObj: any) {
  return {
    type: SELECTED_FACHSCHLUESSELID_ARRAY_SET,
    payload: fachschluesselIDArray,
  };
}
*/
export function selectedGroupIDSet(groupID: any) {
  return {
    type: SELECTED_GROUPID_SET,
    payload: groupID,
  };
}
