import Action from '../../utils/redux/Action';
import actionsDenoter from '../../utils/redux/actions.denoter';

export const APP = 'routes/App';
const action = actionsDenoter(APP);

export const AUTHENTICATION_REQUESTED = action('authenticationRequested');
export function authenticationRequested(): Action {
  return { type: AUTHENTICATION_REQUESTED };
}

export const SET_IS_AUTHENTICATED_REQUESTED = action('setIsAuthenticatedRequested');
export function setIsAuthenticatedRequested(payload: { isAuthenticated: boolean }): Action {
  return { type: SET_IS_AUTHENTICATED_REQUESTED, payload };
}
