// Global imports.
import React from 'react';
// import authentication from 'react-azure-b2c';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

// Views, containers and other components.
import App from './routes/App';
// import { PageLayout } from './components/PageLayout';
// Stylesheets and media.
import './index.css';

// Utils, actions, sagas, etc. .
import MeasureListReducer from './routes/MeasureList/reducer';
import { APP } from './routes/App/actions';
import { appStateReducer } from './routes/App/reducer';
import rootSagas from './utils/redux/root.sagas';

/**
 * Application entry point.
 *
 * @author michael.morosov
 * @author dogus.erguezel
 *
 * @since 2022-02-17
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */

const loggerMiddleware = createLogger({ collapsed: true });
const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  [APP]: appStateReducer,
  MeasureList: MeasureListReducer,
});
const rootStore = createStore(
  rootReducer,
  applyMiddleware(
    sagaMiddleware,
    loggerMiddleware,
  ),
);

sagaMiddleware.run(rootSagas);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render((
  <Provider store={rootStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>));
