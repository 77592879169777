// Global imports.
import { call } from 'redux-saga/effects';
import { notification } from 'antd';

// Views, containers and other components.
import Action from './Action';

/**
 * try-catch saga used as a wrapper, instead of explicitly define try-catch blocks.
 * In case of errors a notification with the error message will popup (antd).
 *
 * I.e., instead of defining explicitly a try-catch block inside a saga
 *
 * ```typescript
 * function* testSaga(action: Action) {
 *  try {
 *    // try something
 *  } catch (err: any) {
 *    // handle error
 *  }
 * }
 * ```
 *
 * We can wrap this function directly while sagas initialization:
 *
 * ```typescript
 * function* watchTest() {
 *  yield takeEvery(tryCatchSaga(testSaga));
 * }
 * ```
 *
 * @author michael.morosov
 *
 * @since 2022-02-02
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
const tryCatchSaga = (saga: any, ...args: any) => function* generatedSaga(action: Action) {
  try {
    yield call(saga, ...args, action);
  } catch (err: any) {
    const message: string = [
      err.constructor?.name || err.name,
      err.message || '',
      err.target?.error?.message || '',
      err.stack || '',
      err.constraints ? JSON.stringify(
        /object/i.test(typeof err.constraints) ? err.constraint : {},
      ) : '',
    ].join(' \n');

    yield notification.error({ message, duration: 0 });
  }
};

export default tryCatchSaga;
