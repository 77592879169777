/* eslint-disable react/destructuring-assignment */

// Global imports.
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Views, containers and other components.
import CreateMeasure from '../CreateMeasure';
import Dashboard from '../Dashboard';
import Footer from '../../components/Footer';
import HeaderBar from '../../components/HeaderBar';
import MeasureList from '../MeasureList';

// Stylesheets and media.
import styles from './styles.module.css';

// Utils, actions, sagas, etc. .
import {
  USER_EMAIL,
  USER_FIRSTNAME,
  USER_ID,
  USER_ID_TOKEN,
  USER_LASTNAME,
  USER_NAME,
  getFromStorage,
} from '../../utils/local.storage';
import { authenticationRequested } from './actions';
import { selectIsAuthenticated } from './reducer';

/**
 * Main application mask.
 *
 * @author michael.morosov
 * @author tim.pauly
 * @author dogus.erguezel
 *
 * @since 2022-03-17
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code
 *  without explicit permission by PLEdoc GmbH.
 */
function App() {
  const dispatch = useDispatch<any>();
  const userIsAuthenticated: boolean = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (!userIsAuthenticated) {
      dispatch(authenticationRequested());
    }
  }, [userIsAuthenticated]);

  return (
    <div className={styles.App}>
      {!!getFromStorage(USER_NAME) && (
        <>
          {/* Header */}
          <HeaderBar
            firstname={localStorage.getItem(USER_FIRSTNAME)}
            lastname={localStorage.getItem(USER_LASTNAME)}
            userID={localStorage.getItem(USER_ID)}
            idToken={localStorage.getItem(USER_ID_TOKEN)}
            mail={localStorage.getItem(USER_EMAIL)}
          />
          {/* Routes */}
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/main" element={<Dashboard />} />
            <Route path="/list" element={<MeasureList userID={localStorage.getItem(USER_ID)} />} />
            <Route path="/create" element={<CreateMeasure userID={localStorage.getItem(USER_ID)} />} />
          </Routes>
          {/* Footer */}
          <Footer />
        </>
      )}
    </div>
  );
}
export default App;
