import axios from 'axios';
import moment from 'moment';
import { getBearerToken } from '../utils/authentication.utils';
import { config } from './Config';
import { URL } from '../utils/constants';

export default class MeasureService {
  public static async getAll(idToken: string | null): Promise<any> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.get(`${URL}api/measure`, axiosConfig);
      // Sortieren der Daten
      const measure = response.data.sort(
        (a: any, b: any) => +moment(b.lastChangedOn).toDate() - +moment(a.lastChangedOn).toDate(),
      );
      return measure;
    } catch (error) {
      console.error('Fehler beim Abrufen aller Maßnahmen:', error);
      throw new Error('Fehler beim Abrufen aller Maßnahmen.');
    }
  }

  public static async getMeasuresOfUsersCompany(userID: string | number, idToken: string | null): Promise<any> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.get(`${URL}api/measure/${userID}`, axiosConfig);
      // Sortieren der Daten
      return response.data.sort(
        (a: any, b: any) => +moment(b.lastChangedOn).toDate() - +moment(a.lastChangedOn).toDate(),
      );
    } catch (error) {
      console.error('Fehler beim Abrufen der Maßnahmen des Benutzers:', error);
      throw new Error('Fehler beim Abrufen der Maßnahmen des Benutzers.');
    }
  }

  public static async create(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await axios.post(`${URL}api/measure`, dataToPost, axiosConfig);
    } catch (error) {
      console.error('Fehler beim Anlegen der Maßnahme:', error);
      throw new Error('Die Maßnahme konnte nicht angelegt werden!');
    }
  }

  public static async update(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.put(`${URL}api/measure`, dtp, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Maßnahme:', error);
      throw new Error('Measure konnte nicht aktualisiert werden!');
    }
  }

  public static async delete(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.delete(`${URL}api/measure/${dtp}`, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Löschen der Maßnahme:', error);
      throw new Error('PointToMovement konnte nicht gelöscht werden!');
    }
  }
}
