/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  FacebookFilled, InstagramFilled, LinkedinFilled, TwitterOutlined, YoutubeFilled,
} from '@ant-design/icons';
import { Layout } from 'antd';
import styles from './styles.module.css';

// ...

/**
 *
 *
 * @author tim.pauly
 *
 * @since 2023-08-09
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code
 *  without explicit permission by PLEdoc GmbH.
 */
function Footer(props: any) {
  return (
    <Layout.Footer className={styles.container}>
      {/* impressum,  */}
      <div className={styles.wrapperImpressum}>
        <a className={styles.link} href="https://oge.net/de/impressum" target="_blank" rel="noreferrer">
          Impressum
        </a>
        <a className={styles.link} href="https://oge.net/de/datenschutz" target="_blank" rel="noreferrer">
          Datenschutz
        </a>
        <div>
          Disclaimer
        </div>
      </div>
      {/* link icons */}
      <div className={styles.wrapperIcons}>
        <a href="https://www.facebook.com/OpenGridEurope/?locale=de_DE" target="_blank" rel="noreferrer" aria-label="Open Grid Europe auf Facebook">
          <FacebookFilled />
        </a>
        <a href="https://www.instagram.com/open_grid_europe/?hl=de" target="_blank" rel="noreferrer" aria-label="Open Grid Europe auf Instagram">
          <InstagramFilled />
        </a>
        <a href="https://twitter.com/OpenGridEurope?s=20" target="_blank" rel="noreferrer" aria-label="Open Grid Europe auf Twitter">
          <TwitterOutlined />
        </a>
        <a href="https://de.linkedin.com/company/open-grid-europe" target="_blank" rel="noreferrer" aria-label="Open Grid Europe auf LinkedIn">
          <LinkedinFilled />
        </a>
        {/* Xing - https://www.xing.com/pages/opengrideuropegmbh */}
        {/* Kununu  - https://www.kununu.com/de/open-grid-europe */}
        <a href="https://www.youtube.com/user/opengrideurope" target="_blank" rel="noreferrer" aria-label="Open Grid Europe auf YouTube">
          <YoutubeFilled />
        </a>
      </div>

      {/* oge */}
      <div className={styles.wrapperOGE}>
        © Open Grid Europe GmbH 2023
      </div>
    </Layout.Footer>
  );
}
export default Footer;
