/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styles from './styles.module.css';
import { selectedMeasureElementSet, selectedFachschluesselArraySet, selectedGroupIDSet } from './actions';
import { getColumns } from './utils';
import MeasureShortening from '../../models/MeasureShortening';
import Group from '../../models/group';
import PointMovementService from '../../services/PointMovementService';
import GroupService from '../../services/GroupService';
import MeasureToPointService from '../../services/MeasureToPointService';
import MeasureService from '../../services/MeasureService';
import CompanyService from '../../services/CompanyService';
import MailService from '../../services/MailService';
import PIService from '../../services/PIService';
import { exportCSV } from '../../utils/ExportCSV';
import { USER_ID_TOKEN } from '../../utils/local.storage';
/**
 *
 * @author tim.pauly
 * @author dogus.erguezel
 *
 * @since 2022-02-17
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */

// eslint-disable-next-line global-require

function MeasureList(props: any) {
  const { dispatch } = props;
  const { userID } = props;
  const navigate = useNavigate();
  const [measure, setMeasure]: [any[], any] = useState([]);
  const [company, setCompany] = useState('');
  const [pointMovement, setPointMovement]: [any[], any] = useState([]);
  const [group, setGroup]: [any[], any] = useState([]);
  const [measureToPoint, setMeasureToPoint]: [any[], any] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys]: [any[], any] = useState([]);
  const [companyName, setCompanyName]: [string, any] = useState('');
  // const messageDelete = 'Die Maßnahme kann nicht gelöscht werden! ';
  useEffect(() => {
    fetchMeasureCompany();
    fetchPointMovement();
    fetchGroup();
    fetchMeasureToPoint();
  }, []);
  useEffect(() => {

  }, [measure]);

  useEffect(() => {
    fetchCompanyName();
  }, [company]);

  const fetchCompanyName = async () => {
    const companyElement = await CompanyService.getCompanyById(company, localStorage.getItem(USER_ID_TOKEN));
    setCompanyName(companyElement[0].companyName);
  };
  const fetchMeasureCompany = async () => {
    const userCompanyElement = await CompanyService.getCompanyByUserId(userID, localStorage.getItem(USER_ID_TOKEN));
    setCompany(userCompanyElement[0].companyID);
    setMeasure(await MeasureService.getMeasuresOfUsersCompany('', localStorage.getItem(USER_ID_TOKEN)));
  };
  const fetchGroup = async () => {
    setGroup(await GroupService.getAll(localStorage.getItem(USER_ID_TOKEN)));
  };
  const fetchPointMovement = async () => {
    setPointMovement(await PointMovementService.getAll(localStorage.getItem(USER_ID_TOKEN)));
  };
  // Fetch all measureToPoint
  const fetchMeasureToPoint = async () => {
    setMeasureToPoint(await MeasureToPointService.getAll(localStorage.getItem(USER_ID_TOKEN)));
  };
  // Get all groups for a measure
  const getMeasureShortenings = (id: any): MeasureShortening[] => {
    const shortenings: MeasureShortening[] = [];
    group.forEach((g: Group) => {
      if (+g.measureID === +id) {
        shortenings.push({
          groupID: g.groupID,
          fromDate: `${g.fromDate}`,
          toDate: `${g.toDate}`,
          tvk: g.tvk,
        });
      }
    });
    return shortenings;
  };
  // Get all Points for a measure
  const getMeasurePointShortenings = (id: any): any[] => {
    const shortenings: any[] = [];
    group.forEach((g: Group) => {
      if (+g.measureID === +id) {
        const points = pointMovement.filter((pm: any) => +pm.groupID === +g.groupID);
        points.forEach((p: any) => shortenings.push(p));
      }
    });
    return shortenings;
  };
  // Remove measure
  const handleMeasureRemove = (measureID: string) => {
    // Send PUT request to 'measure/delete' endpoint
    Modal.confirm({
      title: 'Are you sure, yout want to delete?',
      okText: 'Yes',
      okType: 'danger',
      onOk: () => {
        MeasureService.delete([{
          measureID,
        }], localStorage.getItem(USER_ID_TOKEN)).then(() => {
          GroupService.delete([{
            measureID,
          }], localStorage.getItem(USER_ID_TOKEN)).then(() => {
            group.forEach((element) => {
              if (element.measureID === measureID) {
                groupID = element.groupID;
              }
            });
            if (groupID) {
              PointMovementService.delete([{
                groupID,
              }], localStorage.getItem(USER_ID_TOKEN));
            }
          }).then(() => {
            MeasureToPointService.delete([{
              measureID,
            }], localStorage.getItem(USER_ID_TOKEN));
          }).then(() => {
            Modal.info({
              content: 'Die Maßnahme wurde gelöscht!',
              onOk() {
                fetchMeasureCompany();
              },
            });
          });
        });
      },
    });
    let groupID;
  };
  const handleMeasureCancel = (
    measureID: string,
    mail: string,
    from: string,
    subject: string,
    to: string,
  ) => {
    Modal.confirm({
      title: 'Möchten Sie die Maßnahme wirklich stornieren?',
      okText: 'Ja',
      okType: 'danger',
      onOk: () => {
        MailService.send([{
          measureID, mail, from, subject, to,
        }], localStorage.getItem(USER_ID_TOKEN)).then(() => {
          Modal.success({
            content: 'Die Mail wurde versandt!',
            onOk() {
              MeasureService.update([{
                measureID,
                isCanceled: 1,
              }], localStorage.getItem(USER_ID_TOKEN));
              navigate('/');
            },
          });
        }).catch(() => {
          Modal.error({
            content: 'Die Mail konnte nicht versandt werden!',
            onOk() {
              navigate('/');
            },
          });
        });
      },
    });
  };
  const editMeasure = (measure) => {
    const fachschluesselIDArray: any = [];
    let groupID = 0;
    measureToPoint.forEach((element) => {
      if (element.measureID === measure.measureID) {
        const fachschluesselObj = {
          id: element.id,
          fachschluesselID: element.fachschluesselID,
        };
        fachschluesselIDArray.push(fachschluesselObj);
      }
    });
    group.forEach((element) => {
      if (+element.measureID === measure.measureID) {
        groupID = element.groupID;
      }
    });
    dispatch(selectedFachschluesselArraySet(fachschluesselIDArray));
    dispatch(selectedMeasureElementSet(measure));
    dispatch(selectedGroupIDSet(groupID));

    navigate('/create');
  };

  const isButtonDisabled = (obj: any):boolean => !(companyName === obj.company);

  const rowSelection: any = {
    selectedRowKeys,
    onChange: (rows: any[]) => {
      setSelectedRowKeys(rows);
    },
    getCheckboxProps: (obj: any) => (
      {
        disabled: obj.isForwarded !== false || obj.shorteningRecorded !== true,
      }),
  };
  const getPointIDs = (measureID: string) => {
    const pointArray: any[] = [];
    for (let i = 0; i < group.length; i++) {
      if (+group[i].measureID === +measureID) {
        for (let j = 0; j < pointMovement.length; j++) {
          if (+pointMovement[j].groupID === +group[i].groupID) {
            pointArray.push(+pointMovement[j].pointID);
          }
        }
      }
    }
    return pointArray;
  };

  const submit = async () => {
    let gefunden = false;

    for (let x = 0; x < selectedRowKeys.length; x += 1) {
      const id = selectedRowKeys[x];

      for (let index = 0; index < pointMovement.length; index++) {
        const points = getPointIDs(id);

        for (let y = 0; y < points.length && !gefunden; y++) {
          if (pointMovement[index].pointID === points[y]) {
            if (pointMovement[index].dzkBookable || pointMovement[index].fzk || pointMovement[index].bfzk
              || pointMovement[index].dzk || pointMovement[index].dzkOne || pointMovement[index].bzk) {
              if (points.length - 1 === y) {
                const xmlData = {
                  measure: measure.filter((m: any) => m.measureID === +id)[0],
                  selectedRowKeys: measureToPoint.filter((mp: any) => +mp.measureID === +id),
                  measureShortening: getMeasureShortenings(id),
                  pointShortening: getMeasurePointShortenings(id),
                };
                try {
                  const idToken = localStorage.getItem(USER_ID_TOKEN);
                  // Array of promises for the POST requests
                  const postRequests = [
                    PIService.postPIPM(xmlData, idToken),
                    PIService.postPIBW(xmlData, idToken),
                  ];
                  Promise.all(postRequests)
                    .then(() =>
                      MeasureService.update([{
                        measureID: xmlData.measure.measureID,
                        isForwarded: 1,
                        isReleased: 1,
                        lastChangedOn: moment().utc(),
                      }], idToken))
                    .catch((error) => {
                      // Handle any errors from the POST requests
                      console.error('Error during POST requests:', error);
                      Modal.error({
                        content: 'Maßnahme konnte nicht bearbeitet werden!',
                        onOk() {
                          navigate('/');
                        },
                      });
                    });

                  // Send email
                  const dataToPost = [{
                    measureID: id.toString(),
                    mail: `Die Maßnahme mit der ID ${id} wurde erfolgreich versendet!`,
                    from: xmlData.measure.mail,
                    subject: `Maßnahme: ${id} wurde angelegt`,
                    to: 'extern.massnahmenportal@oge.net',
                  }];

                  // No 'await' here
                  MailService.send(dataToPost, localStorage.getItem(USER_ID_TOKEN))
                    .then(() => {
                      // Success modal and resetting selected row keys
                      Modal.success({
                        content: 'Die Maßnahme wurde erfolgreich an OGE übermittelt und die E-Mail versandt!',
                        onOk() {
                          navigate('/');
                        },
                      });

                      setSelectedRowKeys([]);
                    })
                    .then(() => {
                      navigate('/');
                    })
                    .catch((err) => {
                      console.error(err);
                      Modal.error({
                        content: 'Fehler beim Übermitteln der Maßnahme oder beim Versenden der E-Mail!',
                      });
                    });
                } catch (err) {
                  console.error(err);
                  Modal.error({
                    content: 'Fehler beim Übermitteln der Maßnahme oder beim Versenden der E-Mail!',
                  });
                }
              }
            } else {
              gefunden = true;
              Modal.info({
                content: `Mindestens eine Checkbox, beim Anlegen der Kürzung, muss angewählt werden! Die Maßnahme mit der ID: ${id} konnte aus diesem Grund nicht angelegt werden`,
                onOk() {
                  // Additional logic if needed
                },
              });
            }
          }
        }
      }
    }
  };

  function expandedRow(measure: any) {
    return (
      <div>
        <div>
          Wenn Sie die Maßnahme stornieren wollen, klicken Sie auf den Button.
          Es wird automatisch eine Mail versendet und ihr Anliegen wird bearbeitet.
        </div>
        <div className={styles.btnCancel}>
          <Button onClick={() => handleMeasureCancel(`${measure.measureID}`, 'Die Maßnahme, die in der Betreffzeile durch die ID gekennzeichnet ist, soll gelöscht werden!', measure.mail, `Stornierung der Maßnahme: ${measure.measureID}`, 'extern.massnahmenportal@oge.net')}>
            <MailOutlined />
          </Button>
        </div>
      </div>
    );
  }
  const hasSelected = selectedRowKeys.length > 0;
  if (company) {
    return (
      <div>
        <div className={styles.btnSubmit}>
          <Button type="primary" onClick={submit} disabled={!hasSelected}>
            Absenden
          </Button>
        </div>
        <div className={styles.btnSubmit}>
          <Button type="primary" onClick={() => exportCSV(measure)}>
            Export CSV
          </Button>
        </div>

        <Table
          rowSelection={rowSelection}
          expandable={{
            expandedRowRender: (measure) => <p style={{ margin: 0 }}>{expandedRow(measure)}</p>,
            rowExpandable: (measure) => {
              let open = false;
              if (measure.isCanceled === true) {
                open = false;
              } else if (measure.isForwarded === true) {
                open = true;
              } else {
                open = false;
              }
              return open;
            },
          }}
          dataSource={measure}
          columns={getColumns(isButtonDisabled, editMeasure, handleMeasureRemove)}
          rowKey={(measure) => measure.measureID}
        />
      </div>
    );
  }
  return (
    <div>
      Es wird geprüft, ob Sie bereits einem Unternehmen zugeordnet sind.
      Sollte dies noch nicht der Fall sein, läuft eine Evaluierung durch die Open-Grid-Europe.
    </div>
  );
}
export default connect()(MeasureList);
