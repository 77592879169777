import axios from 'axios';
import { getBearerToken } from '../utils/authentication.utils';
import { config } from './Config';
import { URL } from '../utils/constants';

export default class MeasureToPointService {
  public static async getAll(idToken: string | null): Promise<any> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.get(`${URL}api/measureToPoint`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Fehler beim Abrufen von MeasureToPoint:', error);
      throw new Error('Fehler beim Abrufen von MeasureToPoint.');
    }
  }

  public static async create(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.post(`${URL}api/measureToPoint`, dtp, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Anlegen von MeasureToPoint:', error);
      throw new Error('MeasureToPoint konnte nicht angelegt werden!');
    }
  }

  public static async delete(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.delete(`${URL}api/measureToPoint/${dtp.id}`, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Löschen von MeasureToPoint:', error);
      throw new Error('MeasureToPoint konnte nicht gelöscht werden!');
    }
  }
}
