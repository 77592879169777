import axios from 'axios';
import { getBearerToken } from '../utils/authentication.utils';
import { config } from './Config';
import Company from '../models/company';
import { URL } from '../utils/constants';

export default class CompanyService {
  public static async getCompanyByUserId(userID: string | number, idToken: string | null): Promise<any> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.get(`${URL}api/user_company/${userID}`, axiosConfig);
      return response.data; // Erfolgreiche Antwort zurückgeben
    } catch (error) {
      console.error('Fehler beim Abrufen der Unternehmensdaten:', error);
      throw new Error('Fehler beim Abrufen der Unternehmensdaten. Bitte versuchen Sie es später erneut.');
    }
  }

  public static async getCompanyById(companyID: string | number, idToken: string | null): Promise<any> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.get(`${URL}api/company/${companyID}`, axiosConfig);
      return response.data; // Erfolgreiche Antwort zurückgeben
    } catch (error) {
      console.error('Fehler beim Abrufen der Unternehmensdaten:', error);
      throw new Error('Fehler beim Abrufen der Unternehmensdaten. Bitte versuchen Sie es später erneut.');
    }
  }

  public static async getAll(idToken: string | null): Promise<Company[]> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.get<Company[]>(`${URL}api/company/all`, axiosConfig);
      return response.data; // Erfolgreiche Antwort zurückgeben
    } catch (error) {
      console.error('Fehler beim Abrufen aller Unternehmen:', error);
      throw new Error('Fehler beim Abrufen aller Unternehmen. Bitte versuchen Sie es später erneut.');
    }
  }
}
