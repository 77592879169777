import React, { useEffect } from 'react';
import { Modal, Table } from 'antd';
import cols from './columns';
/**
 *
 *
 * @author dogus.erguezel
 *
 * @since 2022-02-17
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
function ModalPoint(props: any) {
  // eslint-disable-next-line react/destructuring-assignment

  const {
    isVisible, handleCancel, data,
  } = props;
  const columns: any = [
    ...cols,
  ];
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    props;
  });

  return (
    <div>
      <Modal
        title="betroffene Punkte"
        open={isVisible}
        destroyOnClose
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        width={1000}
      >
        <Table
          dataSource={data}
          columns={columns}
        />
      </Modal>
    </div>
  );
}
export default ModalPoint;
