import axios from 'axios';
import { getBearerToken } from '../utils/authentication.utils';
import { config } from './Config';
import { URL } from '../utils/constants';

const controller = new AbortController();

export default class GroupService {
  public static async getAll(idToken: string | null): Promise<any> {
    console.log(`HUHUUU22222222222${idToken}`);
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.get(`${URL}api/group`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Fehler beim Abrufen aller Gruppen:', error);
      throw new Error('Fehler beim Abrufen aller Gruppen.');
    } finally {
      controller.abort();
    }
  }

  public static async create(dataToPost: any, idToken: string | null): Promise<string> {
    try {
      console.log(`HUHUUU22222222222${idToken}`);
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.post(`${URL}api/group`, dataToPost, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Fehler beim Erstellen der Gruppe:', error);
      throw new Error('Die Gruppe konnte nicht angelegt werden!');
    }
  }

  public static async update(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.put(`${URL}api/group`, dtp, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Gruppe:', error);
      throw new Error('Die Gruppe konnte nicht aktualisiert werden!');
    }
  }

  public static async delete(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.delete(`${URL}api/group/${dtp.id}`, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Löschen der Gruppe:', error);
      throw new Error('Die Gruppe konnte nicht gelöscht werden!');
    }
  }
}
