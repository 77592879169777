/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { Button, Layout, Tooltip } from 'antd';
import {
  LeftOutlined,
  UserDeleteOutlined,
  QuestionOutlined,
  HomeOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  useMsal,
} from '@azure/msal-react';
import logo from '../../utils/images/OGELogo.png';
import styles from './styles.module.css';
import {
  USER_EMAIL, USER_FIRSTNAME, USER_ID, USER_ID_TOKEN, USER_LASTNAME,
} from '../../utils/local.storage';

const { Header } = Layout;
/**
 *
 * @author tim.pauly
 * @author dogus.erguezel
 *
 * @since 2022-02-17
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
function HeaderBar(props: any) {
  const {
    firstname, lastname, userID, idToken,
  } = props;
  const { instance } = useMsal();
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  const logout = async () => {
    await instance.logoutRedirect();
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_FIRSTNAME);
    localStorage.removeItem(USER_LASTNAME);
    localStorage.removeItem(USER_ID_TOKEN);
    localStorage.removeItem(USER_EMAIL);
  };
  return (
    <Layout>
      <Header className={styles.header}>
        <div className={styles.wrapper}>
          {/* Home */}
          <div className={styles.home}>
            <Button onClick={() => navigate('/')}>
              <HomeOutlined />
            </Button>
          </div>
          {/* Navigate back button */}
          <div className={styles.back}>
            <Button onClick={back}>
              <LeftOutlined />
            </Button>
          </div>
          {/* Logo and title */}
          <img className={styles.logo} src={logo} />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.portal}>
            Maßnahmenportal
          </div>
        </div>
        <div className={styles.wrapper}>
          {/* Question */}
          <div className={styles.question}>
            <Tooltip
              placement="topLeft"
              title="Bei Fragen rund um die App wenden sie sich an die folgende Email: extern.maßnahmenportal@oge.net"
            >
              <Button>
                <QuestionOutlined />
              </Button>
            </Tooltip>
          </div>
          {/* Logout Button */}
          <div className={styles.logout}>
            <Button onClick={logout}>
              <LogoutOutlined />
            </Button>
          </div>
          {/* Name of the user */}
          <div className={styles.userInformation}>
            {firstname}
            {' '}
            {lastname}
          </div>
        </div>
      </Header>
    </Layout>
  );
}

export default HeaderBar;
