/* eslint-disable default-param-last */
import { SELECTED_MEASURE_ELEMENT_SET, SELECTED_FACHSCHLUESSELID_ARRAY_SET, SELECTED_GROUPID_SET } from './actions';

export enum MeasureState {
  // eslint-disable-next-line no-unused-vars
  PUBLISHED,
  // eslint-disable-next-line no-unused-vars
  TRANSMITTED,
}

const initialState: any = {
  selectedMeasureElement: undefined,
  selectedFachschluesselIDArray: undefined,
  selectedGroupID: undefined,
};

// action. { type: string, payload: any }

const MeasureListReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
  case SELECTED_MEASURE_ELEMENT_SET:
    return { ...state, selectedMeasureElement: action.payload };
  case SELECTED_FACHSCHLUESSELID_ARRAY_SET:
    return { ...state, selectedFachschluesselIDArray: action.payload };
  case SELECTED_GROUPID_SET:
    return { ...state, selectedGroupID: action.payload };
  default:
    return state;
  }
};

export default MeasureListReducer;

export const selectSelectedMeasureElement = (globalState: any) => globalState.MeasureList.selectedMeasureElement;
// eslint-disable-next-line max-len
export const selectSelectedFachschluesselIDArray = (globalState: any) => globalState.MeasureList.selectedFachschluesselIDArray;
export const selectSelectedGroupID = (globalState: any) => globalState.MeasureList.selectedGroupID;
