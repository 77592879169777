import axios from 'axios';
import { getBearerToken } from '../utils/authentication.utils';
import { config } from './Config';
import DataPoint from '../models/datapoint';
import { URL } from '../utils/constants';

export default class PointMovementService {
  public static async getAll(idToken: string | null): Promise<DataPoint[]> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      const response = await axios.get<DataPoint[]>(`${URL}api/point_movement`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Fehler beim Abrufen von PointMovements:', error);
      throw new Error('Fehler beim Abrufen von PointMovements.');
    }
  }

  public static async create(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.post(`${URL}api/point_movement`, dtp, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Erstellen von PointMovement:', error);
      throw new Error('Punkt konnte nicht angelegt werden!');
    }
  }

  public static async update(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.put(`${URL}api/point_movement`, dtp, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Aktualisieren von PointMovement:', error);
      throw new Error('MeasureToPoint konnte nicht aktualisiert werden!');
    }
  }

  public static async delete(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await Promise.all(dataToPost.map((dtp: any) => axios.delete(`${URL}api/point_movement/${dtp.id}`, axiosConfig)));
    } catch (error) {
      console.error('Fehler beim Löschen von PointMovement:', error);
      throw new Error('PointToMovement konnte nicht gelöscht werden!');
    }
  }
}
