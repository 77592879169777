// Global imports.
import * as msal from '@azure/msal-browser';

// Utils, actions, sagas, etc. .
import {
  CLIENTID,
  TENANTID,
} from '../utils/constants';
import {
  ACCESS_TOKEN,
  USER_EMAIL,
  USER_FIRSTNAME,
  USER_ID,
  USER_ID_TOKEN,
  USER_LASTNAME,
  USER_NAME,
  getFromStorage,
  removeAuthenticationData,
  saveToStorage,
} from '../utils/local.storage';
import Authenticator from './authenticator';

/**
 * {@link Authenticator} extension for online authentication.
 *
 * @author michael.morosov
 *
 * @since 2022-02-02
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
export default class MSALAuthenticator extends Authenticator {
  protected msalConfiguration!: msal.Configuration;

  protected msalInstance!: msal.PublicClientApplication;

  protected scopes!: Array<string>;

  protected initialized: boolean;

  constructor() {
    super();

    if (!CLIENTID) {
      throw new Error('APP CLIENT ID is not defined! Check your env variables, please.');
    }

    if (!TENANTID) {
      throw new Error('APP TENANT ID is not defined! Check your env variables, please.');
    }

    this.initialized = false;
    this.scopes = [
      'offline_access',
      'openid',
      'https://extmassnahmen.onmicrosoft.com/myapp/user_impersonation',
    ];

    this.msalConfiguration = {
      auth: {
        clientId: CLIENTID,
        authority: 'https://extmassnahmen.b2clogin.com/extmassnahmen.onmicrosoft.com/B2C_1_LOGIN',
        knownAuthorities: ['extmassnahmen.b2clogin.com'],
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };

    this.msalInstance = new msal.PublicClientApplication(this.msalConfiguration);
  }

  public async initialize(): Promise<void> {
    if (!this.initialized) {
      await this.msalInstance.initialize();

      this.initialized = true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public isAuthenticated(): boolean {
    return !!getFromStorage(ACCESS_TOKEN);
  }

  public async authenticate() {
    await this.initialize();

    if (navigator.onLine) {
      try {
        await this.acquireTokenSilently();
      } catch (err: any) {
        await this.showLoginPopup();
      }
    }
  }

  public async getToken(scopes: Array<string>): Promise<string> {
    await this.initialize();

    const getAccessToken = (result: msal.AuthenticationResult) => result.accessToken;

    try {
      return await this.acquireTokenSilently(getAccessToken, scopes || this.scopes);
    } catch (err: any) {
      return this.showLoginPopup(getAccessToken, scopes);
    }
  }

  public async logOut() {
    await this.initialize();
    const account: msal.AccountInfo | null = this.getCurrentAccount();
    await this.msalInstance.logoutPopup({
      account,
      postLogoutRedirectUri: window.location.origin,
    }).then(() => {
      removeAuthenticationData();
    });
  }

  private async showLoginPopup(
    handler = MSALAuthenticator.handleAuthenticationResult,
    scopes = this.scopes,
  ): Promise<any> {
    await this.msalInstance
      .loginPopup({ scopes })
      .then(handler);
  }

  private getCurrentAccount(): msal.AccountInfo | null {
    const accessToken: string | null = getFromStorage(ACCESS_TOKEN);
    const username: string | null = getFromStorage(USER_NAME);
    const email: string | null = getFromStorage(USER_EMAIL);

    if (accessToken && username && email) {
      return this.msalInstance.getAccountByUsername(email);
    }

    return null;
  }

  private async acquireTokenSilently(
    handler = MSALAuthenticator.handleAuthenticationResult,
    scopes = this.scopes,
  ): Promise<any> {
    const currentAccount: msal.AccountInfo | null = this.getCurrentAccount();

    if (currentAccount != null) {
      return this.msalInstance.acquireTokenSilent({
        account: currentAccount,
        scopes,
        forceRefresh: false,
      }).then(handler);
    }

    throw Error('stored account does not exist!');
  }

  private static handleAuthenticationResult(result: msal.AuthenticationResult): any {
    const {
      accessToken,
      account,
    } = result;
    saveToStorage(ACCESS_TOKEN, accessToken);
    saveToStorage(USER_NAME, account?.username ?? '');
    saveToStorage(USER_EMAIL, account?.username ?? '');
    saveToStorage(USER_ID_TOKEN, account?.idToken ?? '');
    saveToStorage(USER_ID, account?.localAccountId ?? '');
    if (account?.idTokenClaims) {
      const givenName = account.idTokenClaims.given_name as string; // Typumwandlung zu string
      const familyName = account.idTokenClaims.family_name as string; // Typumwandlung zu string
      saveToStorage(USER_FIRSTNAME, givenName);
      saveToStorage(USER_LASTNAME, familyName);
    }
  }
}
