import axios from 'axios';
import { getBearerToken } from '../utils/authentication.utils';
import { config } from './Config';
import { URL } from '../utils/constants';

export default class PIService {
  public static async postPIPM(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await axios.post(`${URL}api/sap_pi_pm`, dataToPost, axiosConfig);
    } catch (error) {
      console.error('Fehler beim Senden an OGE:', error);
      throw new Error('Konnte nicht an OGE gesendet werden!');
    }
  }

  public static async postPIBW(dataToPost: any, idToken: string | null): Promise<void> {
    try {
      const finalToken = idToken || await getBearerToken();
      const axiosConfig = config(finalToken);
      await axios.post(`${URL}api/sap_pi_bw`, dataToPost, axiosConfig);
    } catch (error) {
      console.error('Fehler beim Senden an OGE:', error);
      throw new Error('Konnte nicht an OGE gesendet werden!');
    }
  }
}
