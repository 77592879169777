/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import {
  Form, Button, Input, InputNumber, Checkbox,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

// eslint-disable-next-line import/prefer-default-export
export function getShorteningColumns(
  measureElement: any,
  fromDate: Date,
  toDate: Date,
  editingRowIndex: any,
  form: any,
  setEditingRowIndex: (id: any) => void,
  onChangeGroupToDate: (event: any, groupID: number) => void,
  onChangeGroupFromDate: (event: any, groupID: number) => void,
  onFinishGroup: (value: any) => void,
  onDeleteGroup: (id: number) => void,
): ColumnType<any>[] {
  return [
    {
      title: 'Gruppen-ID',
      dataIndex: 'groupID',
      key: 'groupID',
    },
    {
      title: 'Von-Datum',
      dataIndex: 'fromDate',
      key: 'fromDate',
      render: (text: any, record: any) => (
        <input
          type="date"
          value={moment(record.fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
          min={moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
          max={moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
          onChange={(e) => onChangeGroupFromDate(e, record.groupID)}
        />
      ),
    },
    {
      title: 'Bis-Datum',
      dataIndex: 'toDate',
      key: 'toDate',
      render: (text: any, record: any) => (
        <input
          type="date"
          value={moment(record.toDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
          min={moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
          max={moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
          onChange={(e) => onChangeGroupToDate(e, record.groupID)}
        />
      ),
    },
    {
      title: 'Von-Zeit',
      dataIndex: '',
      key: 'time',
      render: () => (
        <Form.Item name="times1" initialValue="06:00">
          <Input disabled />
        </Form.Item>
      ),
    },
    {
      title: 'Bis-Zeit',
      dataIndex: '',
      key: 'time',
      render: () => (
        <Form.Item name="times2" initialValue="06:00">
          <Input disabled />
        </Form.Item>
      ),
    },
    {
      title: 'TVK mit Massnahme',
      dataIndex: 'tvk',
      key: 'tvk',
      // eslint-disable-next-line consistent-return
      render: (text: any, obj: any) => {
        if (editingRowIndex === obj.groupID) {
          return (
            <Form.Item
              name="tvk"
              rules={[
                {
                  required: true,
                  message: 'Please enter your tvk',
                },
              ]}
            >
              <InputNumber
                onBlur={
                  (e: any) => {
                    setEditingRowIndex(obj.groupID);
                    form.setFieldsValue({
                      tvk: obj.tvk,
                    });
                    onFinishGroup({ ...obj, groupID: obj.groupID, tvk: +e.target.value });
                  }
                }
                formatter={(value) => `${value}`}
                parser={(value) => `${value}`.replace(',', '.')}

              />
            </Form.Item>
          );
        }
        return <p>{text}</p>;
      },
    }, {
      title: 'Gruppe entfernen',
      dataIndex: '',
      key: 'delete',
      render: (obj: any) => (obj.isForwarded !== 1 ? (
        <div>
          <Button
            disabled={measureElement?.isForwarded === true}
            onClick={() => {
              setEditingRowIndex(obj.groupID);
              form.setFieldsValue({
                tvk: obj.tvk,
              });
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            style={{ marginLeft: '1rem' }}
            disabled={measureElement?.isForwarded === true}
            onClick={() => onDeleteGroup(obj.groupID)}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Button>
        </div>
      ) : (
        ''
      )),
    },
  ];
}

export function getColumnsPoints(
  measureElement: any,
  handleCheckboxChangeFactoryDZKBOOKABLE: (checked: any, entry: any) => any,
  handleCheckboxChangeFactoryFZK: (checked: any, entry: any) => any,
  handleCheckboxChangeFactoryBFZK: (checked: any, entry: any) => any,
  handleCheckboxChangeFactoryDZK: (checked: any, entry: any) => any,
  handleCheckboxChangeFactoryDZKONE: (checked: any, entry: any) => any,
  handleCheckboxChangeFactoryBZK: (checked: any, entry: any) => any,
  setEditingRowPointIndex: (value: any) => void,
  onEditPoint: (record: any) => void,
  onDeletePoint: (pID: number) => void,
) : any {
  return [
    {
      title: 'Punkt/VIP',
      dataIndex: 'node',
    },
    {
      title: 'Webname',
      dataIndex: 'webname',
    },
    {
      title: 'Richtung',
      dataIndex: 'direction',
    },
    {
      title: 'Punktart',
      dataIndex: 'pointType',
    },
    {
      title: 'VIP',
      dataIndex: 'vip',
    },
    {
      title: 'DZK buchbar',
      dataIndex: 'dzkBookable',
      render: (checked: any, entry: any) => (
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChangeFactoryDZKBOOKABLE(checked, entry)}
          disabled={measureElement?.isForwarded === true}
        />
      ),
    },
    {
      title: 'FZK',
      dataIndex: 'fzk',
      render: (checked: any, entry: any) => (
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChangeFactoryFZK(checked, entry)}
          disabled={measureElement?.isForwarded === true}
        />
      ),
    },
    {
      title: 'bFZK',
      dataIndex: 'bfzk',
      render: (checked: any, entry: any) => (
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChangeFactoryBFZK(checked, entry)}
          disabled={measureElement?.isForwarded === true}
        />
      ),
    },
    {
      title: 'DZK',
      dataIndex: 'dzk',
      render: (checked: any, entry: any) => (
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChangeFactoryDZK(checked, entry)}
          disabled={measureElement?.isForwarded === true}
        />
      ),
    },
    {
      title: 'DZK1',
      dataIndex: 'dzkOne',
      render: (checked: any, entry: any) => (
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChangeFactoryDZKONE(checked, entry)}
          disabled={measureElement?.isForwarded === true}
        />
      ),
    },
    {
      title: 'BZK',
      dataIndex: 'bzk',
      render: (checked: any, entry: any) => (
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChangeFactoryBZK(checked, entry)}
          disabled={measureElement?.isForwarded === true}
        />
      ),
    },
    {
      title: 'Punkt entfernen',
      dataIndex: '',
      key: 'delete',
      render: (obj: any) => (
        <div>
          <Button
            disabled={measureElement?.isForwarded === true}
            onClick={() => {
              setEditingRowPointIndex(obj.pointID);
            }}
          >
            <EditOutlined
              onClick={() => {
                onEditPoint(obj);
              }}
            />
          </Button>
          <Button
            disabled={measureElement?.isForwarded === true}
            onClick={() => onDeletePoint(obj.pointID)}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Button>
        </div>
      ),
    },
  ];
}
