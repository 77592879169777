/**
 * Collection of methods and function to work with local/session storage.
 *
 * @author michael.morosov
 *
 * @since 2022-02-02
 *
 * @copyright PLEdoc GmbH - All rights reserved.
 * You may not use, distribute or modify this code without explicit permission by PLEdoc GmbH.
 */
const storage: Storage = localStorage;

// Authentication
export const STORAGE_PREFIX = 'ext-massnahme';
export const ACCESS_TOKEN = 'ext-massnahme:access-token';
export const USER_FIRSTNAME = 'ext-massnahme:user-firstname';
export const USER_LASTNAME = 'ext-massnahme:user-lastname';
export const USER_ID = 'ext-massnahme:user-id';
export const USER_EMAIL = 'ext-massnahme:user-email';
export const USER_NAME = 'ext-massnahme:user-name';
export const USER_ID_TOKEN = 'ext-massnahme:user-id-token';

// Application
export function getFormattedStorageKey(key: string): string {
  return `${STORAGE_PREFIX}:${key.replace(STORAGE_PREFIX, '').replace(/^:+/, '')}`;
}

export function saveToStorage(key: string, value: string): void {
  storage.setItem(getFormattedStorageKey(key), value);
}

export function getFromStorage(key: string): string | null {
  const item: string | null = storage.getItem(getFormattedStorageKey(key));

  if (item === 'undefined') {
    return null;
  }

  return item;
}

export function removeFromStorage(...keys: string[]): void {
  keys.forEach((key: string) => storage.removeItem(key));
}

export function removeAuthenticationData(): void {
  removeFromStorage(ACCESS_TOKEN, USER_EMAIL, USER_NAME);
}
